export const agentDetailsConfig = {
    headings: [
        {
            headingLabel: "Location",
            border: true,
            width: "20%"
        },
        {
            headingLabel: "Phone",
            border: true,
            width: "20%"
        },
        {
            headingLabel: "Address",
            border: true,
            width: "50%"
        },
        // {
        //     headingLabel: "",
        //     border: true,
        //     width: "5%"
        // },
        // {
        //     headingLabel: "",
        //     border: true,
        //     width: "5%"
        // }
    ],
    dataNodes: ["location_name", "phone", "address"],
    actions: ["pen", "circle", "minus-circle"],
    primaryKey: "agency_location_id",
    starIcon: ["location_name"] 
}

export const agencyLocationList = [
    {
        location: "LA Office",
        phone: "(999)-888-7654",
        address: "1300 Hollywood Blvd., Suite 1000 Los Angeles, CA 90210"
    },
    {
        location: "NY Office",
        phone: "(999)-888-7654",
        address: "1300 Hollywood Blvd., Suite 1000 Los Angeles, CA 90210"
    },
    {
        location: "LA Office",
        phone: "(999)-888-7654",
        address: "1300 Hollywood Blvd., Suite 1000 Los Angeles, CA 90210"
    }
]

export const agencyRepsConfig = {
    headings: [
        {
            headingLabel: "Representative Name",
            border: true,
            width: "45%"
        },
        {
            headingLabel: "Covered Talent",
            border: true,
            width: "45%"
        },
        // {
        //     headingLabel: "",
        //     border: true,
        //     width: "10%"
        // }
    ],
    dataNodes: ["representative_name", "covered_talent"],
    actions: [""],
    hyperlinks: ["representative_name"]
}

export const agencyRepsList = [
    {
        representative_name: "Tony Josephson",
        covered_talent: "Amy Jones (PKA Amy Smith, Amy Jackson)| Evil Kinievel(PKA Amy Smith, Amy Jackson)"
    },
    {
        representative_name: "Tony Josephson",
        covered_talent: "Amy Jones (PKA Amy Smith, Amy Jackson)| Evil Kinievel(PKA Amy Smith, Amy Jackson)"
    }, {
        representative_name: "Tony Josephson",
        covered_talent: "Amy Jones (PKA Amy Smith, Amy Jackson)| Evil Kinievel(PKA Amy Smith, Amy Jackson)"
    }
]

export const agencyTalentsConfig = {
    headings: [
        {
            headingLabel: "Covered Talent",
            border: true,
            width: "25%"
        },
        {
            headingLabel: "Rep. Name",
            border: true,
            width: "25%"
        },
        {
            headingLabel: "Occupation",
            border: true,
            width: "50%"
        }
    ],
    dataNodes: ["talent_name", "representative_name", "occupation"],
    hyperlinks: ["talent_name", "representative_name"]
}

export const agencyTalentsList = [
    {
        covered_talent: "Abby McDouglas",
        rep_name: "Tony Joesphson Smith Jr.",
        type: "Agent"
    },
    {
        covered_talent: "Abby McDouglas",
        rep_name: "Tony Joesphson Smith Jr.",
        type: "Agent"
    },
    {
        covered_talent: "Abby McDouglas",
        rep_name: "Tony Joesphson Smith Jr.",
        type: "Attorney"
    }
]

export const initialAgencySocial = {
    object_id: null,
    object_type: null,
    value: null,
    is_primary: 0,
    is_delete:0 
}
export const newAgencyDetailsObj = {
    "agency_id": null,
    "agency_name": null,
    "occupation": null,
    "agency_notes": null,
    "agency_type": null,
    "akas": [],
    "location_list": [],
    "social_list": [],
    "representative_list": []
  }
export const locationConfig = 
    {
        "agency_location_id": null,
        "type": "new",
        "location_name": null,
        "address_type": null,
        "address": null,
        "city": null,
        "zip": null,
        "state": null,
        "country": null,
        "is_primary": 0,
        "is_delete": 0,
        "phone_list": [
            {
                "object_id": null,
                "object_type": null,
                "value": null,
                "is_primary": 1,
                "is_delete": 0,
                "ext":null 
            }
        ],

    }

    export const initialPhoneObject = {
        "object_id": null,
        "object_type": null,
        "value": null,
        "is_primary": 0,
        "is_delete": 0,
        "ext": null
    }


export const initialAliasConfig = {
    "agency_aliases_id": null,
    "alias": null,
    "is_primary": 0,
    "is_delete": 0
}

export const repsFilterConfig = {
    "covered_talent": null,
    "representative_name": null
}

export const talentsFilterConfig = {
    "talent_name": null,
    "rep_name": null,
    "type": null
}